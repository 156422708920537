<template lang="pug">
div
  .row
    .col-md-12.text-center
      h2 RECIBO
  .row
    .col-md-8.text-center
    .col-md-4.text-rigth
      | R$ _________________
  .row
    vue-element-loading(:active='isActive', spinner='bar-fade-scale', color='#428BCA')
    .col-md-12.padding-row
      br
      br
      p
        | Recebi de&nbsp;
        strong {{clientes.nome}}
        | , a quantia de
        strong ________________________________________________________________
        | , referente ao pagamento da confecção do(s) móvel(is) planejado(s)
        br
        strong
          |  {{vendas.produtos}}
        | .
      br
      br
      h4 Dados do Cliente:
      p
        | Nome:
        strong  {{clientes.nome}}
      p
        | CPF:
        strong  {{clientes.cpf_cnpj}}
      p
        | Tel:
        strong  {{clientes.telefone1}}
      p
        | End:
        strong  {{clientes.end_rua}}
        |  n°
        strong  {{clientes.end_numero}}
      p
        | Comp.
        strong  {{clientes.end_compl}}
        |  Bairro:
        strong  {{clientes.end_bairro}}
      p
        | CEP:
        strong  {{clientes.end_cep}}
        |  Cidade/UF:
        strong  {{clientes.end_cidade}}-{{clientes.end_estado}}
      br
      br
      p
        | Previsão de Instalação:
        strong  {{vendas.previsao_inst | dataCad}}
      p
        | Taxa de Adesão:
        strong  {{vendas.tx}}
      p
        | Vendedor(a):
        strong  {{vendas.usuario_nome}}
      br
      br
      p Campo Grande/MS, ___de_____________de {{anoAtual}}
      br
      br
      br
      br
      p.text-center
        | ______________________________________
        br
        | 				LigNet Telecom
      br
      br
      .text-center.no-print
        a.btn.btn-success(@click.prevent='getImprimir', href='#')
          i.fa.fa-print(aria-hidden='true')
          |  Imprimir
</template>

<script>
import moment from 'moment'
import req from '@/components/request'
import VueElementLoading from 'vue-element-loading'
import {erro} from '@/components/Message'
moment.locale('pt-BR')

export default {
  components: {
    VueElementLoading
  },
  filters: {
    dataHora (x) {
      return moment(x).format('L LT')
    },
    dataCad (x) {
      return moment(x).format('L')
    }
  },
  data () {
    return {
      vlrvenda: 0,
      vendas: {},
      clientes: [],
      isActive: false,
      anoAtual: moment().startOf('day').startOf('month').format('YYYY')
    }
  },
  methods: {
    getRecibo () {
      req(`/vendas/${this.$route.params.id}`).then(resp => {
        this.vendas = resp
        this.getCliente(resp.clientes_id)
      }).catch(response => {
        console.log('error: ' + response)
        erro()
      })
    },
    getCliente (id) {
      req(`/clientes/${id}`).then(resp => {
        delete resp.solicitante
        delete resp.usuario_nome
        delete resp.usuario_cad_id
        delete resp.usuario_cad_nome
        delete resp.datacad
        delete resp.datacad_atu
        delete resp.mae
        delete resp.mae_fantasia
        delete resp.id
        delete resp.usuario_id
        this.clientes = {...this.clientes, ...resp}
        this.getEndereco(id)
      }).catch(response => {
        console.log('error: ' + response)
        erro()
      })
    },
    getEndereco (id) {
      req(`/clientes/${id}/endereco`).then(resp => {
        delete resp.principal
        delete resp.cliente_id
        delete resp.usuario_id
        delete resp.id
        this.clientes = {...this.clientes, ...resp}
        this.isActive = false
      }).catch(response => {
        console.log('error: ' + response)
        erro()
      })
    },
    getImprimir () {
      window.print()
    }
  },
  mounted () {
    this.isActive = true
    this.getRecibo()
  }
}
</script>

<style lang="stylus" scoped>

</style>
